const lightTheme = {
  colors: {
    white: '#FFFFFF',
    black: '#29293A',
    primary: '#323c8e',
    secondary: '#f55b41',
    secondaryHover: '#FA6A52',
    cyan: '#6DC7DD',
    softCyan: '#66C4DB',
    darkBlue: '#033878',
    lightBlue: '#d4def2',
    lightPurple: '#D4DEF2',
    lightBlueText: '#6CA8CE',
    backgroundLightBlue: '#EAF6FB',
    textBlue: '#2A6379',
    red: '#DD0000',
    pink_red: '#E73458',
    pink: '#E73458',
    gray: '#C4C4C4',
    dark_gray: '#747474',
    lightGreyText: '#777777',
    textLightBlue: '#607D8B',
    textDarkBlue: '#29293A',
    border: '#666666',
    border_2: '#BBBCBD',
    border_3: '#EEEEEE',
    border_4: '#E2E2E2',
    white_with_opacity_3: 'rgba(255,255,255,0.3)',
    white_with_opacity_5: 'rgba(255,255,255,0.5)',
    white_with_opacity_7: 'rgba(255,255,255,0.7)',
    white_with_opacity_9: 'rgba(255,255,255,0.9)',
    black_with_opacity_05: 'rgba(0,0,0,0.05)',
    black_with_opacity_1: 'rgba(0,0,0,0.1)',
    black_with_opacity_3: 'rgba(0,0,0,0.3)',
    black_with_opacity_7: 'rgba(0,0,0,0.7)',
    black_with_opacity_9: 'rgba(0,0,0,0.9)',
    transparent: 'rgba(0, 0, 0, 0)',
    nav_purple: '#24226A',
    font_main: '#24226A',
    font_secondary: '#323c8e',
    link_main: '#323c8e',
    link_secondary: '#f55b41',
    yellow: '#fdc400',
    orange: '#F39325',
  },
  backgrounds: {
    primary:
      'rgb(48, 49, 51, 1) 50%,rgba(48, 49, 51, 0.6) 80%,rgba(48, 49, 51, 0) 100%',
    black: 'rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 80%,rgba(0,0,0,0) 100%',
  },
  shadows: {
    modal:
      '0px 84px 249px rgba(0, 0, 0, 0.17), 0px 39.4172px 160.331px rgba(0, 0, 0, 0.12), 0px 25.105px 93.355px rgba(0, 0, 0, 0.06), 0px 17.0007px 52.719px rgba(0, 0, 0, 0.05), 0px 10.8828px 28.2042px rgba(0, 0, 0, 0.04), 0px 5.39884px 11.9255px rgba(0, 0, 0, 0.02)',
    simple: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    average: '0px 15px 50px rgba(0, 0, 0, 0.2)',
  },
  hover: {
    transition: 'transition: all 0.5s ease-in-out;',
    action:
      'transform: translateY(-10px); box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.2);',
  },
};

const darkTheme: Theme = {
  colors: {
    white: '#FFFFFF',
    black: '#29293A',
    primary: '#323c8e',
    secondary: '#f55b41',
    secondaryHover: '#A5D426',
    cyan: '#6DC7DD',
    softCyan: '#66C4DB',
    darkBlue: '#033878',
    lightBlue: '#d4def2',
    lightPurple: '#D4DEF2',
    lightBlueText: '#6CA8CE',
    backgroundLightBlue: '#EAF6FB',
    textBlue: '#2A6379',
    red: '#DD0000',
    pink_red: '#E73458',
    pink: '#E73458',
    gray: '#C4C4C4',
    dark_gray: '#747474',
    lightGreyText: '#777777',
    textLightBlue: '#607D8B',
    textDarkBlue: '#29293A',
    border: '#666666',
    border_2: '#BBBCBD',
    border_3: '#EEEEEE',
    border_4: '#E2E2E2',
    white_with_opacity_3: 'rgba(255,255,255,0.3)',
    white_with_opacity_5: 'rgba(255,255,255,0.5)',
    white_with_opacity_7: 'rgba(255,255,255,0.7)',
    white_with_opacity_9: 'rgba(255,255,255,0.9)',
    black_with_opacity_05: 'rgba(0,0,0,0.05)',
    black_with_opacity_1: 'rgba(0,0,0,0.1)',
    black_with_opacity_3: 'rgba(0,0,0,0.3)',
    black_with_opacity_7: 'rgba(0,0,0,0.7)',
    black_with_opacity_9: 'rgba(0,0,0,0.9)',
    transparent: 'rgba(0, 0, 0, 0)',
    nav_purple: '#24226A',
    font_main: '#24226A',
    font_secondary: '#323c8e',
    link_main: '#323c8e',
    link_secondary: '#f55b41',
    yellow: '#fdc400',
    orange: '#F39325',
  },
  backgrounds: {
    primary:
      'rgb(48, 49, 51, 1) 50%,rgba(48, 49, 51, 0.6) 80%,rgba(48, 49, 51, 0) 100%',
    black: 'rgba(0,0,0,1) 50%,rgba(0,0,0,0.6) 80%,rgba(0,0,0,0.9) 100%',
  },
  shadows: {
    modal:
      '0px 84px 249px rgba(0, 0, 0, 0.17), 0px 39.4172px 160.331px rgba(0, 0, 0, 0.12), 0px 25.105px 93.355px rgba(0, 0, 0, 0.06), 0px 17.0007px 52.719px rgba(0, 0, 0, 0.05), 0px 10.8828px 28.2042px rgba(0, 0, 0, 0.04), 0px 5.39884px 11.9255px rgba(0, 0, 0, 0.02)',
    simple: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    average: '0px 15px 50px rgba(0, 0, 0, 0.2)',
  },
  hover: {
    transition: 'transition: all 0.5s ease-in-out;',
    action:
      'transform: translateY(-10px); box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.2);',
  },
};

export const SHADOWS = {
  modal:
    '0px 84px 249px rgba(0, 0, 0, 0.17), 0px 39.4172px 160.331px rgba(0, 0, 0, 0.12), 0px 25.105px 93.355px rgba(0, 0, 0, 0.06), 0px 17.0007px 52.719px rgba(0, 0, 0, 0.05), 0px 10.8828px 28.2042px rgba(0, 0, 0, 0.04), 0px 5.39884px 11.9255px rgba(0, 0, 0, 0.02)',
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
