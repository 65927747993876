import styled from 'styled-components/macro';

interface IProps {
  open: boolean;
}

export const StyledBurger = styled.button<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 5px;

  &:focus {
    outline: none;
  }

  .BarsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: ${({ open }) => (open ? 'space-around' : 'space-between')};
    width: 30px;
    height: 20px;
    padding: 2.5px 7px;

    div {
      width: 100%;
      height: 1px;
      background: ${p => p.theme.colors.lightBlue};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
        transform: ${({ open }) =>
          open ? 'translateX(20px)' : 'translateX(0)'};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      }
    }
  }

  p {
    color: ${p => p.theme.colors.white};
    display: block;
    font-size: 10px;
    line-height: 1.3;
    white-space: nowrap;
  }
`;

export default StyledBurger;
