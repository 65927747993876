export const menu = [
  // {
  //   name: 'products',
  //   slug: '/products',
  // },
  {
    name: 'webinars',
    slug: '/webinars',
  },
  {
    name: 'applications-and-calculators',
    slug: '/applications-and-calculators',
  },
  {
    name: 'share-with-patient',
    slug: '/doctor-and-visitor',
  },
];
