import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  html {
    height: 100%;
    width: 100%;
    line-height: 1.4;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${p => p.theme.colors.textDarkBlue};
    background-color: white;
    overflow-y: scroll;
    overflow-x: clip;
    -webkit-overflow-scrolling: touch;
    
    * {
      font-family: 'Poppins', sans-serif;
    }
    
    .pac-container {
      &:after {
        content: none;
      } 
    
      .pac-item {
        padding: 10px 15px;
        
        .pac-icon {
          display: none;
        }
      }
    }
  }
  
  .react-tabs {
    width: 100%;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 310px;
    min-height: 100%;
    z-index: 0;
  }
  
  h1, h2, p {
    margin: 0;
  }
  
  input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
  }
  
  iframe {
    max-width: 100%;
  }
  
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  
  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    
    li {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      padding-left: 30px;
      margin-bottom: 10px;
      
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: ${props => props.theme.colors.primary};
      }
      
      &:last-child {
        margin: 0;
      }
    }
  }
  
  ol {
    width: 100%;
    padding: 0 20px;
    
    li {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
  }
  
  .Toastify__toast--success {
    background: #91c461;
  }
  .Toastify__toast--warning {
    background: #fdc400;
  }
  .Toastify__toast--error {
    background: #f55b41;
  }
`;
