/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { PrivateRoute } from './utils/PrivateRoute';
import { useInjectReducer, useInjectSaga } from './utils/redux-injectors';

// moment js
import * as moment from 'moment';
import 'moment/locale/uk';
import 'moment-duration-format';

// react toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

// main pages
import { HomePage } from './containers/HomePage/Loadable';
import { SignUpPage } from './containers/SignUpPage/Loadable';
import { NotFoundPage } from './components/layout/NotFoundPage/Loadable';

import { PracticalExperiencePage } from './containers/PracticalExperiencePage/Loadable';
import { PracticalExperienceSinglePage } from './containers/PracticalExperienceSinglePage/Loadable';

import { VideoLibraryPage } from './containers/VideoLibraryPage/Loadable';
import { VideoLibrarySinglePage } from './containers/VideoLibrarySinglePage/Loadable';

import { AboutProjectPage } from './containers/AboutProjectPage/Loadable';

import { BroadcastingLibraryPage } from './containers/BroadcastingLibraryPage/Loadable';
import { BroadcastingSinglePage } from './containers/BroadcastingSinglePage/Loadable';
import { CalculatorsPage } from './containers/CalculatorsPage/Loadable';

import { DoctorAndVisitorPage } from './containers/DoctorAndVisitorPage/Loadable';
import { DoctorAndVisitorSinglePage } from './containers/DoctorAndVisitorSinglePage/Loadable';
import { DoctorAndVisitorVideoSinglePage } from './containers/DoctorAndVisitorVideoSinglePage/Loadable';

import { ClinicalCaseSinglePage } from './containers/ClinicalCaseSinglePage/Loadable';

import { ClinicalCasePage } from './containers/ClinicalCasePage/Loadable';

import { UsefulLinksPage } from './containers/UsefulLinksPage/Loadable';

// profile
import { ProfileLayout } from './containers/ProfileLayout/Loadable';

// auth pages
import { LoginPage } from './containers/LoginPage/Loadable';
import { ResetPasswordByEmailPage } from './containers/ResetPassword/ResetPasswordByEmailPage/Loadable';
import { ResetPasswordByPhonePage } from './containers/ResetPassword/ResetPasswordByPhonePage/Loadable';
import { VerificationRestorePasswordBySmsPage } from './containers/VerificationBySmsCode/VerificationRestorePasswordBySmsPage/Loadable';
import { RestorePasswordPage } from './containers/RestorePasswordPage/Loadable';
import { ThanksForRegister } from './containers/ThanksForRegister/Loadable';
import { UserAlreadyConfirmed } from './containers/UserAlreadyConfirmed/Loadable';
import { UnauthorizedPage } from './containers/UnauthorizedPage/Loadable';
import { ProductsPage } from './containers/ProductsPage/Loadable';
import { ProductsSinglePage } from './containers/ProductsSinglePage/Loadable';

// layout
import { AppLayout } from './layouts/AppLayout';
import ModalLayout from './layouts/ModalLayout';

import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';

import { PADUACalculatorPopup } from './components/features/Calculators/CalculatorsPopups/PADUA/Loadable';
import { DoseCalculator } from './components/features/Calculators/CalculatorsPopups/DOSE/Loadable';
import { BodyAreaCalculator } from './components/features/Calculators/CalculatorsPopups/BodyArea/Loadable';

// locales
import { useTranslation } from 'react-i18next';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions,
} from './store/app/slice';

import { appSaga } from './store/app/saga';

import { useDispatch } from 'react-redux';

// axios
import { $apiClient } from 'utils/request';

// hoc
import withErrorHandler from 'hoc/withErrorHandler';

// utils
import ScrollToTop from './utils/scrollToTop';
import { AuthPage } from './components/layout/AuthPage';

// toast modal configuration
toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
});

export function App() {
  // inject reducer && saga
  useInjectReducer({ key: appSliceKey, reducer: appReducer });
  useInjectSaga({ key: appSliceKey, saga: appSaga });

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.checkAuthStatusTrigger());
  }, [dispatch]);

  const { i18n } = useTranslation();

  useEffect(() => {
    moment.locale('uk');
  }, []);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="GICancerExpert – %s"
        defaultTitle="GICancerExpert"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="GICancerExpert" />
      </Helmet>

      <ScrollToTop />

      <Header />

      <AppSwitch />

      <Footer />
      <GlobalStyle />
    </BrowserRouter>
  );
}

function AppSwitch() {
  const location = useLocation();

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname !== '/login' &&
      location.pathname !== '/thanks-for-register' &&
      location.pathname !== '/user-already-confirmed' &&
      location.pathname !== '/restore-password' &&
      location.pathname !== '/profile'
    )
      dispatch(actions.setLocation(location.pathname));
  }, []);

  const [success, setSuccess] = useState(false);

  const closeModalWindow = value => {
    setSuccess(value);
  };

  // @ts-ignore
  const background = location.state && location.state.background;

  return (
    <AppLayout>
      <Switch location={background || location}>
        <Route exact path="/cross_auth" component={AuthPage} />

        <Route
          path={'/thanks-for-register'}
          render={() => (
            <ModalLayout>
              <ThanksForRegister />
            </ModalLayout>
          )}
        />
        <Route
          path={'/user-already-confirmed'}
          render={() => (
            <ModalLayout>
              <UserAlreadyConfirmed />
            </ModalLayout>
          )}
        />

        <Route
          path={'/unauthorised'}
          render={() => (
            <ModalLayout isUnauthorised>
              <UnauthorizedPage />
            </ModalLayout>
          )}
        />

        <Route exact path="/" component={HomePage} />

        <Route
          exact
          path="/registration"
          render={() => (
            <SignUpPage closePopupMainComponent={closeModalWindow} />
          )}
        />

        <Route exact path="/about-project" component={AboutProjectPage} />

        <Route exact path="/clinical-cases" component={ClinicalCasePage} />

        <PrivateRoute
          exact
          path="/clinical-cases/:slug"
          component={ClinicalCaseSinglePage}
        />

        <Route exact path="/products" component={ProductsPage} />

        <PrivateRoute
          exact
          path="/products/:slug"
          component={ProductsSinglePage}
          singlePage={true}
        />

        <Route
          exact
          path="/practical-experience"
          component={PracticalExperiencePage}
        />
        <Route
          exact
          path="/practical-experience/:slug"
          component={PracticalExperienceSinglePage}
        />

        <Route exact path="/video-library" component={VideoLibraryPage} />
        <Route
          exact
          path="/video-library/:slug"
          component={VideoLibrarySinglePage}
        />

        <Route exact path="/webinars" component={BroadcastingLibraryPage} />

        <PrivateRoute
          exact
          path="/webinars/:slug"
          component={BroadcastingSinglePage}
        />

        <Route
          exact
          path="/applications-and-calculators"
          component={CalculatorsPage}
        />

        <PrivateRoute
          path={'/applications-and-calculators/calculators/padua-scale'}
          component={PADUACalculatorPopup}
        />

        <PrivateRoute
          path={'/applications-and-calculators/calculators/dose'}
          component={DoseCalculator}
        />

        <PrivateRoute
          path={'/applications-and-calculators/calculators/body-area'}
          component={BodyAreaCalculator}
        />

        <Route
          exact
          path="/doctor-and-visitor"
          component={DoctorAndVisitorPage}
        />

        <Route
          exact
          path="/doctor-and-visitor/:slug"
          component={DoctorAndVisitorSinglePage}
        />

        <Route
          exact
          path="/doctor-and-visitor-video/:slug"
          component={DoctorAndVisitorVideoSinglePage}
        />

        <Route exact path="/useful-links" component={UsefulLinksPage} />

        <Route path="/profile" component={ProfileLayout} />

        <Route component={NotFoundPage} />

        <Redirect to="/" />
      </Switch>

      <Route
        exact
        path={'/login*'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <LoginPage activeTab={0} />
          </ModalLayout>
        )}
      />

      <Route
        exact
        path={'/registration'}
        render={() => (
          <ModalLayout isLoginAndRegistration closeModalPopup={success}>
            <LoginPage
              activeTab={1}
              closePopupMainComponent={closeModalWindow}
            />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-email'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-phone'}
        render={() => (
          <ModalLayout>
            <ResetPasswordByPhonePage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password-by-sms-code'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/verification-mobile-phone'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <RestorePasswordPage />
          </ModalLayout>
        )}
      />
    </AppLayout>
  );
}

export default withErrorHandler(App, $apiClient);
